import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import Footer from '../components/FooterSite'

import { globalStyles } from '../components/layout.styles'

import {
  container,
  about,
  contactForm,
  label,
  checkBox,
  btn,
  formInputs,
  formInputsLr,
  inputsItem, 
  checksItems
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Veredas - Caminho alternativo através do qual é possível chegar mais rápido a um determinado destino." />
    <NavBar location={props.location} />
    <div>
      <div css={about}>
        <div css={container} style={{ marginTop: 40, width: '100%' }}>
          <div css={contactForm}>
            <h2>Quero conhecer o projeto <span>#escolasparaofuturo</span></h2>
            <form method="post" action="#" data-netlify="true" name="contact" style={{ margin: '0 auto', marginBottom: 90 }}>
              <input type="hidden" name="form-name" value="contact" />
              <div css={label}>
                <p>Nome:</p>
                <input type="text" name="name" id="name" required />
              </div>
              <div css={label}>
                <p>E-mail:</p>
                <input type="text" name="email" id="email" required />
              </div>
              <div css={label}>
                <p>Whatsapp:</p>
                <input type="text" name="whatsapp" id="whatsapp" required />
              </div>
              <div css={label}>
                <p>Escola:</p>
                <input type="text" name="school" id="school" required />
              </div>
              <div css={checkBox}>
                <p>Na Escola você é:</p>
                <div css={checksItems}>
                  <div css={[formInputs, inputsItem]}> 
                    <input id="option1" name="option1" type="checkbox" />
                    <label for="option1">Professor</label>
                  </div>
                  <div css={[formInputs, formInputsLr]}> 
                    <input id="option2" name="option2" type="checkbox" />
                    <label for="option2">Coordenador</label>
                  </div>
                </div>
                <div css={checksItems}>
                  <div css={[formInputs, inputsItem]}> 
                    <input id="option3" name="option3" type="checkbox" />
                    <label for="option3">Diretor</label>
                  </div>
                  <div css={[formInputs, formInputsLr]}> 
                    <input id="option4" name="option4" type="checkbox" />
                    <label for="option4">Pai</label>
                  </div>
                </div>
                <div css={checksItems}>
                  <div css={[formInputs, inputsItem]}> 
                    <input id="option5" name="option5" type="checkbox" />
                    <label for="option5">Aluno</label>
                  </div>
                </div>
              </div>
              <button type="submit" css={btn}>CADASTRE-SE</button>
            </form> 
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
